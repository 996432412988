<template>
  <div class="ytm-logreg-form">
    <p class="ytm-landing-font ytm-logreg-title">Регистрация</p>
    <input
        class="ytm-logreg-input"
        v-model="form_data.username"
        @input="validateUsername"
        placeholder="Имя пользователя (логин)"
        type="text"
        @keyup.enter="submitForm"
        autofocus
    >
    <div style="position: relative; width: 74%; margin-left: 13%; margin-right: 13%; margin-bottom: 4%">
      <input
          class="ytm-logreg-input"
          style="width: 100%; margin: 0"
          v-model="form_data.password"
          @input="validatePassword"
          placeholder="Пароль"
          :type="hidden? 'password' : 'text'"
          @keyup.enter="submitForm"
      >
      <img
          @mousedown="hidden = !hidden"
          :src="hidden ? 'https://img.icons8.com/ios-glyphs/90/000000/hide.png' : 'https://img.icons8.com/ios-glyphs/90/000000/visible--v1.png'"
          style="position: absolute; right: 5%; top: 25%; height: 50%; cursor: pointer; opacity: 0.9"
      />
    </div>
    <p class="ytm-landing-font ytm-logreg-error-text">{{error_message}}</p>
    <button class="ytm-logreg-button" style="margin-left: auto; margin-right: auto; margin-bottom: max(1.5vw, 1.5vh)" @click="submitForm">
                <span class="ytm-landing-font ytm-logreg-button-text" style="margin-right: 1vw">
                  Зарегистрироваться
                </span>
      <svg style="width: max(1.77vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
      </svg>
    </button>
    <p class="ytm-landing-font ytm-or-google" style="margin-bottom: max(1.5vh, 1.5vw)">
      или
    </p>
    <div style="width: 100%; margin-bottom: max(1.25vw, 1.25vh)">
      <button @click="openGoogle" class="ytm-google-login">
        <span class="ytm-landing-font ytm-google-login-span" style="padding-left: max(2.5vw, 1.5rem)">
          <img src="https://img.icons8.com/color/48/000000/google-logo.png"/>
          Продолжить с Google
        </span>
      </button>
    </div>
    <p class="ytm-landing-font ytm-logreg-plain-text" style="padding-bottom: 2.5vw">
      Уже есть профиль?
      <a class="ytm-logreg-link" href="/login" @click.prevent="openLogin" style="cursor: pointer">Войдите</a>
    </p>
  </div>
</template>

<script>
import {inject} from "vue";
import axios from "axios";
import {SERVICE_AUTH_URI} from "@/util/api-host";
import {getGoogleUrl} from "@/util/get-google-url";

export default {
  name: 'RegisterForm',
  setup() {
    let form_data = inject('form_data');
    return {form_data};
  },
  data() {
    return {
      hidden: true,
      error_message: '',
    };
  },
  methods: {
    openGoogle() {
      window.open(getGoogleUrl('/', this.$route.query.to || '/home'), '_self');
    },
    openLogin() {
      this.$router.push({path: '/login', query: this.$route.query});
    },
    validateUsername() {
      this.error_message = '';
      let str = '';
      for (let c of this.form_data.username) {
        if (('0' <= c && c <= '9') || ('a' <= c && c <= 'z') || ('A' <= c && c <= 'Z') || c === '.' || c === '_') {
          str += c;
        } else {
          this.error_message = 'Используйте только большие и малые латинские буквы, цифры, точки и нижние подчёркивания';
        }
      }
      this.form_data.username = str;
      if (this.form_data.username.length > 20) {
        this.form_data.username = this.form_data.username.substr(0, 20);
      }
    },
    validatePassword() {
      this.error_message = '';
      for (let c of this.form_data.password) {
        if (!('a' <= c && c <= 'z') && !('A' <= c && c <= 'Z') && !('0' <= c && c <= '9') && c !== '.' && c !== '_' && c !== '#' && c !== '$' && c !== '?' && c !== '!') {
          this.error_message = 'Пароль может состоять только из больших и малых латинских букв, цифр и символов из набора ._#$?!';
          return;
        }
      }
      if (this.form_data.password.length > 30) {
        this.error_message = 'Слишком длинный пароль – такие обычно забываются :)';
      }
    },
    submitForm() {
      if (this.badUsername() || this.badPassword()) {
        this.form_data.password = '';
        return;
      }
      axios.post(
          SERVICE_AUTH_URI + '/register',
          {
            username: this.form_data.username,
            password: this.form_data.password,
          },
          {},
      ).then(resp => {
        if (resp.data.message !== 'success') {
          this.error_message = resp.data.message;
        } else {
          this.$store.dispatch('auth/login', {
            user_data: this.form_data,
            to: this.$route.query.to || '/home',
          });
        }
      }).catch(err => {
        this.error_message = err.response.data.message;
        this.form_data.password = '';
      });
    },
    badUsername() {
      this.validateUsername();
      if (this.error_message.length > 0) {
        return true;
      }
      if (this.form_data.username.length < 4) {
        this.error_message = 'Имя пользователя должно состоять хотя бы из 4 символов';
        return true;
      }
      if (this.form_data.username[0] === '.' || this.form_data.username[this.form_data.username.length - 1] === '.') {
        this.error_message = 'Имя пользователя не может начинаться с точки или заканчиваться точкой';
        return true;
      }
      return false;
    },
    badPassword() {
      this.validatePassword();
      if (this.error_message.length > 0) {
        return true;
      }
      if (this.form_data.password.length < 8) {
        this.error_message = 'Пароль должен состоять хотя бы из 8 символов';
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>