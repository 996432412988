<template>
  <div style="position: relative; width: 100vw; min-height: 100vh; overflow: hidden">
    <LoginBackground/>
    <div class="ytm-landing-wrapper">
      <LandingTop @scrollToFooter="$router.push('/')"/>
      <div style="position: relative; width: 100%; min-height: 90vh; margin-top: 1vh">
        <div class="ytm-logreg-form-wrapper">
          <RegisterForm/>
        </div>
        <div class="ytm-logreg-image"/>
        <svg class="star22" viewBox="0 0 849 819" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M174.546 49.9508C292.675 -33.9324 456.024 -9.14598 543.951 106.004L563.144 131.139C612.794 196.161 694.75 227.795 775.211 212.995C916.696 186.971 1043.98 303.102 1031 446.374L1024.35 519.749C1018.91 731.476 870.52 912.573 664.009 959.593L599.087 978.864C447.632 1023.82 289.842 930.837 255.788 776.565C241.384 711.313 203.485 653.639 149.306 614.524L133.804 603.332C14.0815 516.897 -31.617 360.175 22.8851 222.938L24.2575 219.482C46.9334 162.384 85.6555 113.072 135.747 77.5017L174.546 49.9508Z" fill="url(#paint0_linear_75_172)"/>
          <defs>
            <linearGradient id="paint0_linear_75_172" x1="371.5" y1="34.5" x2="666.959" y2="970.855" gradientUnits="userSpaceOnUse">
              <stop stop-color="#9747FF"/>
              <stop offset="1" stop-color="#D55EFF"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import LandingTop from "../Navbar";
import LoginBackground from "../LoginPage/Background";
import RegisterForm from "./RegisterForm";
import {ref, provide} from "vue";

export default {
  name: 'RegisterPage',
  components: {RegisterForm, LoginBackground, LandingTop},
  setup() {
    let form_data = ref({
      username: '',
      password: '',
    });
    provide('form_data', form_data);
    return {form_data};
  },
};
</script>

<style scoped>
.star22 {
  position: absolute;
  width: 60vw;
  right: -12.5vw;
  bottom: 0;
  z-index: -100;
}

@media only screen and (max-width: 750px) {
  .star22 {
    bottom: -20%;
    width: 70vw;
  }
}

@media only screen and (max-width: 600px) {
  .star22 {
    right: -5vw;
  }
}

@media only screen and (max-width: 325px) {
  .star22 {
    bottom: 0;
  }
}
</style>